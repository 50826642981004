import React from 'react';
import styled from 'styled-components';

import { HeaderVariables } from '@/layout/components/Header/constants';
import Banner from '@/layout/components/Header/components/Banner';
import Link from '@/components/Link';
import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';
import Dropdown from '@/layout/components/Header/components/Dropdown';
import MenuColumn from '@/layout/components/Header/components/MenuColumn';

import { ModelsDropdownProps } from './types';

function ModelsDropdown({ menuItem }: ModelsDropdownProps) {
  const headerBrands = useSettingItem('HEADER_BRANDS');
  const hasHeaderBrands =
    menuItem.children.some((item) =>
      item.label.includes(HeaderVariables.Brands)
    ) &&
    headerBrands &&
    headerBrands.length > 0;
  const banner = menuItem.children.find((item) =>
    item.label.includes(HeaderVariables.Banner)
  );
  const menu = menuItem.children.filter(
    (item) =>
      !item.label.includes(HeaderVariables.Brands) &&
      !item.label.includes(HeaderVariables.Banner)
  );

  return (
    <Dropdown
      label={menuItem.label.replace(HeaderVariables.Models, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      <Top>
        {hasHeaderBrands && (
          <Brands>
            {headerBrands.map(({ section, items }, index) => (
              <MenuColumn
                key={index}
                title={section ?? ''}
                menu={
                  items && items.length !== 0
                    ? items.map(({ image, name, link, isNewTab }) => ({
                        image,
                        title: name ?? '',
                        link: link ?? '',
                        isNewTab,
                      }))
                    : []
                }
                columns={index === 0 ? 3 : 1}
              />
            ))}
          </Brands>
        )}

        {banner && (
          <Banner
            label={banner.label.replace(HeaderVariables.Banner, '').trim()}
            link={banner.link ?? ''}
            isNewTab={banner.isNewTab}
          />
        )}
      </Top>

      {menu && menu.length > 0 && (
        <Bottom>
          {menu.map((item) => (
            <BottomItem key={item.link}>
              <BottomLink
                to={item.link}
                target={item.isNewTab ? '_blank' : '_self'}
              >
                {item.label}
              </BottomLink>
            </BottomItem>
          ))}
        </Bottom>
      )}
    </Dropdown>
  );
}

export default ModelsDropdown;

const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 30px;
`;

const Brands = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`;

const Bottom = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${colors.gray900};
`;

const BottomItem = styled.li`
  display: flex;
  align-items: flex-start;
`;

const BottomLink = styled(Link)`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.main};
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom-color: ${(props) => props.theme.main};
  }
`;
