import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import OpenMenu from '@/assets/svg/dealer-web/burger-button.svg';
import CloseMenu from '@/assets/svg/dealer-web/close-menu.svg';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import {
  componentColors,
  SubmenuTypes,
} from '@/layout/components/DealerWebHeader/constants';
import { media } from '@/utils/mixin';
import { useDealerWebLinkClick } from '@/layout/hooks';
import Link from '@/components/Link';
import Personal from '@/layout/components/DealerWebHeader/components/Personal';
import useSettingItem from '@/hooks/useSettingItem';
import {
  selectMobileMenuOpened,
  setMobileMenuOpened,
} from '@/store/reducers/layout';

import ModelsDropdown from './components/ModelsDropdown';
import LinkDropdown from './components/LinkDropdown';

function MobileMenuList() {
  const dispatch = useTypedDispatch();
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];
  const dealerWebModels = useSettingItem('HEADER_DEALER_WEB_MODELS');
  const { handleClickLink } = useDealerWebLinkClick();
  const isOpen = useTypedSelector(selectMobileMenuOpened);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  function handleClickMenuItem(
    link: Nullable<string>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    handleClickLink(link, event);
    dispatch(setMobileMenuOpened(false));
  }

  return (
    <Component>
      <BurgerButton
        isOpen={isOpen}
        onClick={() => dispatch(setMobileMenuOpened(!isOpen))}
      >
        {isOpen ? <CloseMenu /> : <OpenMenu />}
      </BurgerButton>

      <Container isOpen={isOpen}>
        <MenuList>
          {headerMenu.map((item, index) => {
            const isModelsDropdown = item.label.includes(SubmenuTypes.Models);

            if (
              isModelsDropdown &&
              dealerWebModels &&
              dealerWebModels.length > 0
            ) {
              return (
                <MenuItemWrapper key={index}>
                  <ModelsDropdown
                    label={item.label.replace(SubmenuTypes.Models, '').trim()}
                    link={item.link ?? ''}
                    isNewTab={item.isNewTab}
                    models={dealerWebModels.map(
                      ({
                        image,
                        name,
                        description,
                        price,
                        link,
                        isNewTab,
                      }) => ({
                        image,
                        name: name ?? '',
                        description: description ?? '',
                        price: price ?? '',
                        link: link ?? '',
                        isNewTab,
                        onClick: handleClickMenuItem,
                      })
                    )}
                    onClick={handleClickMenuItem}
                  />
                </MenuItemWrapper>
              );
            }

            if (item.children && item.children.length !== 0) {
              return (
                <MenuItemWrapper key={index}>
                  <LinkDropdown
                    label={item.label}
                    link={item.link ?? ''}
                    isNewTab={item.isNewTab}
                    menu={item.children.map(({ label, children }) => ({
                      title: label ?? '',
                      menu:
                        children && children.length !== 0
                          ? children.map(({ label, link, isNewTab }) => ({
                              title: label ?? '',
                              link: link ?? '',
                              isNewTab,
                              onClick: handleClickLink,
                            }))
                          : [],
                    }))}
                    onClick={handleClickLink}
                  />
                </MenuItemWrapper>
              );
            }

            return (
              <MenuItemWrapper key={index}>
                <MenuItem
                  to={item.link ?? ''}
                  target={item.isNewTab ? '_blank' : '_self'}
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => handleClickMenuItem(item.link, event)}
                >
                  {item.label ?? ''}
                </MenuItem>
              </MenuItemWrapper>
            );
          })}
        </MenuList>

        <PersonalWrapper>
          <Personal />
        </PersonalWrapper>
      </Container>
    </Component>
  );
}

export default MobileMenuList;

const Component = styled.div`
  margin-left: 20px;
`;

const BurgerButton = styled.button<{ isOpen: boolean }>`
  margin-top: 3px;

  svg {
    path {
      stroke: ${componentColors.headerBurger};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        path {
          stroke: ${componentColors.headerBurgerClose};
        }
      }
    `}
`;

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 11;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow: auto;
  background: ${componentColors.headerBackground};
  transform: translateX(100%);
  transition: 0.3s;

  ${media.mobile(css`
    top: 60px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
    `}
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItemWrapper = styled.div`
  margin-bottom: 30px;
`;

const MenuItem = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  color: ${componentColors.headerNav};
`;

const PersonalWrapper = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${componentColors.headerLine};
`;
